var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}
require('./util')
var renderQue = require('./render');
var stage = require('./stage');
var navbar = require('./scroll');
var swiper = require('swiper');
require("./../../bower_components/gsap/src/uncompressed/TweenMax.js");

var swiperinstance = new Swiper ('.product-swiper', {
  speed: 731,
  observer: true,
  observeParents: true,
  spaceBetween: 0,
  loop: true,
  effect: 'fade',
  fade: {
    crossFade: true
  },
  nextButton: '.arrow-right',
  prevButton: '.arrow-left'
  
})

$('.quantity .minus').on('click', function () {
  var parent = $(this).parent()
  var input = $('.qty', parent)[0];
  input.stepDown(1)
  $('.qty', parent).trigger('change')
})
$('.quantity .plus').on('click', function () {
  var parent = $(this).parent()
  var input = $('.qty', parent)[0];
  input.stepUp(1)
  $('.qty', parent).trigger('change')
})


$('.cart-preview').on('click', '.cart-contents', function () {
  $('.mini-cart').addClass('is-visible')
  Tt.disable()
})

$('.mini-cart').on('click', '.mini-cart__background, .close', function () {
  $('.mini-cart').removeClass('is-visible')
  Tt.enable()
})

$('.mini-cart__bar').on("mousewheel", function(t) {
    t.preventDefault()
    var e = this.scrollTop;
    this.scrollTop = e + t.deltaY * t.deltaFactor * -1
})

var Tt = {
  disable: function() {
      $("body").on("scroll mousewheel touchmove", function(t) {
          t.preventDefault(), t.stopPropagation()
      })
  },
  enable: function() {
      $("body").off("scroll mousewheel touchmove")
  }
};




 
