var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"swiper-slide\" style=\"background-image: url("
    + container.escapeExpression(container.lambda(depth0, depth0))
    + ");\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"swiper-container swiper\" data-slider=\"\">\n  <div class=\"swiper-wrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <button class=\"slider-nav arrow-left\"><svg><use xlink:href='#arrow-left'></use></svg></button>\n    <button class=\"slider-nav arrow-right\"><svg><use xlink:href='#arrow-right'></use></svg></button>\n</div>";
},"useData":true});