var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

var verge = require('verge')
var swiper = require('swiper');
var util = require('util').inherits
var defaultpage = require('./defaultpage')
var imagesLoaded = require('imagesloaded');
require("./../../bower_components/gsap/src/uncompressed/TweenMax.js");

function collection(name, stage){
   
   this.name = name
   this.counter = 7   
   this.stage = stage
   this.el = $('#content')
   this.el_moving = []
   this.el_data = []
   this.swiperinstance = null

}

util(collection, defaultpage)

collection.prototype.scroll = function (event) {

  
   this.el_data = $.map(this.el_moving , function( el ) {
       return verge.rectangle(el).top + 100
     });
     
   for(var m = 0; m < this.el_moving.length; m++) {
      var mover = this.el_moving[m]
      
      if( this.el_data[m] - event.h < 0  ) {
          mover.classList.add('is-visible');
      } else {
          mover.classList.remove('is-visible');
      }
   }
  
}

collection.prototype.resize = function (e) {
  
}

collection.prototype.hide = function() {
  
  var hasvideo = this.modal.hasClass('has-video')
  var hasgallery = this.gmodal.hasClass('has-gallery')
  this.modal.removeClass('has-video')
  this.gmodal.removeClass('has-gallery')
  

  //TweenMax.to( window, 1, {scrollTo:{ y:0, autoKill:true }, ease:Power4.easeOut });
  
  if(hasvideo){
    $('.video-container').empty()
    //thiz.main.removeClass('has-video')
  }
  if(hasgallery){
    $('.gallery-container').empty()
    //thiz.main.removeClass('has-gallery')
  }
  
  TweenMax.killAll(false, false, true, false);
  var h = this.stage.w
  TweenMax.to(this.el, .71, { x: h , alpha: 0, onComplete: bind(this, this.onHidden),  ease: Power4.easeInOut })

}


collection.prototype.onHidden = function() { 
  this.hidden = true
}

collection.prototype.animateOut = function() {
  
  var hasvideo = this.modal.hasClass('has-video')
  var hasgallery = this.gmodal.hasClass('has-gallery')
  this.modal.removeClass('has-video')
  this.gmodal.removeClass('has-gallery')
  

  //TweenMax.to( window, 1, {scrollTo:{ y:0, autoKill:true }, ease:Power4.easeOut });
  
  if(hasvideo){
    $('.video-container').empty()
    //thiz.main.removeClass('has-video')
  }
  if(hasgallery){
    $('.gallery-container').empty()
    //thiz.main.removeClass('has-gallery')
  }
  this.exit()
  TweenMax.killAll(false, false, true, false);
  var h = this.stage.w
  
  time = this.hidden ? 0 : .71
  
  TweenMax.to(this.el, time, {x: h ,alpha: 0,  onComplete: bind(this, this.onComplete), ease: Power4.easeOut})

}

collection.prototype.onComplete = function() {
  
  this.destroy()
  this.dealoc()
  
}

collection.prototype.canEnter = function() {
  
  this.enter()
  $('body').removeClass('is-animating')
  this.el_moving = document.querySelectorAll('.box');   
  this.stage.resize()  
  
  var thiz = this
  
  thiz.gallerylink.on('click', function (event) {
    event.preventDefault()
    //removeOverlay()
    thiz.gmodal.addClass('has-gallery')
    gallerycomplete()
    //thiz.main.addClass('has-gallery')
    //TweenMax.fromTo(thiz.gallery, 1, {scaleX: 0}, {scaleX: 1, onComplete: gallerycomplete})
  })
  
  function gallerycomplete(){
    if(typeof(thiz.swiperinstance) == "function"){
      thiz.swiperinstance.destroy()
    }
    thiz.cgallery.empty().append(thiz.galleryhtml)
    thiz.swiperinstance = new Swiper ('.swiper', {
      speed: 731,
      observer: true,
      observeParents: true,
      spaceBetween: 0,
      loop: true
    })
  } 
  
  this.videolink.on('click', function (event) {
    
    event.preventDefault()
    //removeOverlay()
    //thiz.main.addClass('has-video')
    thiz.currentvideo =  $(this).data('videoid')
    
    TweenMax.delayedCall(1, videocomplete)
//    var videohtml = thiz.videotemplate({videoid:thiz.currentvideo});
//    $('.video-container').empty().append(videohtml)
    
    thiz.modal.addClass('has-video')
    
  })
  
  function videocomplete(){
    var videohtml = thiz.videotemplate({videoid:thiz.currentvideo});
    $('.video-container').empty().append(videohtml)  
  }
  
  function removeOverlay(){
    
    var hasvideo = thiz.modal.hasClass('has-video')
    var hasgallery = thiz.gmodal.hasClass('has-gallery')
    thiz.modal.removeClass('has-video')
    thiz.gmodal.removeClass('has-gallery')
    

    //TweenMax.to( window, 1, {scrollTo:{ y:0, autoKill:true }, ease:Power4.easeOut });
    
    if(hasvideo){
      $('.video-container').empty()
      //thiz.main.removeClass('has-video')
    }
    if(hasgallery){
      $('.gallery-container').empty()
      //thiz.main.removeClass('has-gallery')
    }
  
  }

  
  
  $('.modal-background').on('click', function(event) {
    
    event.preventDefault();
    removeOverlay()
    
  })
  
  $('.cross').on('click', function(event) {
    
    event.preventDefault();
    removeOverlay()
    
  })
  
  $('#gallery-modal').on('click', '.arrow-left', function(event) {
    
    event.preventDefault();
    var swiper = $(this).parent()[0].swiper
    swiper.slidePrev()
    
  })
  
  $('#gallery-modal').on('click', '.arrow-right', function(event) {
    
    event.preventDefault();
    var swiper = $(this).parent()[0].swiper
    swiper.slideNext()
    
  })
  
  
  
}

collection.prototype.headerLoaded = function(instance) {
  
  TweenMax.set(this.el, {alpha:0, x: this.stage.w })
  TweenMax.to(this.el, 1, {alpha: 1, x: 0, ease: Power4.easeInOut, onComplete: bind(this, this.canEnter)})
  this.canEnter()
  
}

collection.prototype.animateIn = function(initialpageload) {
  
  this.initialpageload = initialpageload
  this.initListeners()
  //this.headerLoaded()
  
  this.videolink = $('.video-link')
  this.gallerylink = $('.gallery-link')
  this.main = $('body')
  this.modal = $('#video-modal')
  this.gmodal = $('#gallery-modal')
  
  this.gallery = $('.collection-gallery')
  this.cgallery = $('.gallery-container')
  this.video = $('.collection-video')
  
  this.gallerytemplate = require('../templates/gallery.handlebars')
  this.galleryhtml = this.gallerytemplate(this.gallery.data('images'));
  
  this.videotemplate = require('../templates/video.handlebars')
  
  this.headerLoaded()

}

collection.prototype.dealoc = function () {

   this.imgLoad = null
   this.removeAllListeners()
}


module.exports = collection