var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

NProgress = require('nprogress');
var renderQue = require('./render');
var crossroads = require('crossroads');
var stage = require('./stage');
var homepage = require('./homepage');
var projectpage = require('./collection');
var navbar = require('./scroll');

require("./../../bower_components/gsap/src/uncompressed/TweenMax.js");

var pages = {}
var initialpageload = true
var prevpage = null
var currentpage = null

var dbc = new stage()

var page = crossroads.addRoute('/:home_section:')
var project = crossroads.addRoute('/collection/{projectName}')
var news = crossroads.addRoute('/updates/{projectName}')
var terms = crossroads.addRoute('/shop/{projectName}')

page.matched.add(homeRoute, this)
news.matched.add(newsRoute, this)
terms.matched.add(homeRoute, this)
project.matched.add(projectRoute, this)

function homeRoute(section) {
  section = section == undefined ? "*" : section
  
  if(!pages[section] ){
    pages[section] = new homepage(section, dbc)
  }
  
  prevpage = currentpage
  currentpage = pages[section]
  load()
}

function newsRoute(section) {
  var section = 'updates/' + section 
    
  if(!pages[section] ){
    pages[section] = new homepage(section, dbc)
  }
  
  prevpage = currentpage
  currentpage = pages[section]
  load()
}

function projectRoute(project) {

  //console.log(project)
  var pname = 'collection/' + project

  if(!pages[pname] ){
    pages[pname] = new projectpage( pname, dbc)
  }
  
  prevpage = currentpage
  currentpage = pages[pname]
  load()
}


function render() {
    $('body').addClass('is-animating')
    if(prevpage){
      prevpage.once('page:exit', renderNext)
      prevpage.animateOut()
    }
    else {
      renderNext()
    }
}

function renderNext() {
  //console.log('renderNext')
  $('#content').empty()
  $(window).scrollTop(0)
  $('#content').append(currentpage.template)
  $(document).find("title").text(currentpage.title)
  pageRendered()
  currentpage.animateIn()
}

function pageRendered() {

  //console.log('page rendered ' + currentpage.name)
  NProgress.done()
  
  $('body').removeClass(function (index, css) {
      return (css.match (/ispage-\S+/g) || []).join(' ');
  })
  $('body').addClass('ispage-' + currentpage.name)
  
  $('#content').removeClass(function (index, css) {
      return (css.match (/ispage-\S+/g) || []).join(' ');
  })
  $('#content').addClass('ispage-' + currentpage.name)
  
//  var projectname = getParameterByName('project');
//  console.log(projectname)
//  if(projectname) {
//    var el = $('#'+projectname)
//    var h = el.offset().top - $(window).height()/2 + 320
//    TweenMax.to( window, 0, {scrollTo: { y:h, autoKill: false }, ease:Power4.easeInOut });
//  }
  
}

function load() {

  var islookbook = currentpage.name == "*" || currentpage.name.indexOf("collection") !== -1
  $('.marker').toggleClass('is-hidden', !islookbook)

 if(initialpageload){
   pageRendered()
   currentpage.animateIn(initialpageload)
   initialpageload = false
 }
 else {
    
    if(currentpage.template){  
      render('from cache')
    }
    else {   
    
      if(prevpage){
         //console.log(prevpage)
         prevpage.hide()
      }   
      
      var now = function() {
          return new Date().getTime();
        };
      
      var url = currentpage.name == "*" ? "/" : "/" + currentpage.name
         
      $.ajax({
      	url: url
      }).done(done)
    }
    
  }
}

function done(data, textStatus, jqXHR){

    $data = $(documentHtml(data))
    $dataBody = $data.find('.document-body:first'),
    $dataContent = $dataBody.find('#content').filter(':first')
    contentHtml = $dataContent.html() || $data.html()
    
    // Update the title
		document.title = $data.find('.document-title:first').text();
		try {
			document.getElementsByTagName('title')[0].innerHTML = document.title.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; ');
		}
		catch ( Exception ) { }
    
	  currentpage.template = contentHtml


	  render()
}


var documentHtml = function(html){
	// Prepare
	var result = String(html)
		.replace(/<\!DOCTYPE[^>]*>/i, '')
		.replace(/<(html|head|body|title|meta|script)([\s\>])/gi,'<div class="document-$1"$2')
		.replace(/<\/(html|head|body|title|meta|script)\>/gi,'</div>')
	;
	
	// Return
	return $.trim(result);
};

//function getParameterByName(name, url) {
//    if (!url) url = window.location.href;
//    url = url.toLowerCase(); // This is just to avoid case sensitiveness  
//    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();// This is just to avoid case sensitiveness for query parameter name
//    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//        results = regex.exec(url);
//    if (!results) return null;
//    if (!results[2]) return '';
//    return decodeURIComponent(results[2].replace(/\+/g, " "));
//}

History.Adapter.bind(window,'statechange',function(){ 
    
    var State = History.getState(); 
    var url = State.url
    var relativeUrl = History.getShortUrl(url).replace(/\/$/, "");
    var s = relativeUrl;
    var m = s.indexOf('?')
    var n = m != -1 ?  m : s.length;   
    relativeUrl = s.substring(0, n);
    
    
    NProgress.start()
    //ga('send', 'pageview', {'page': relativeUrl });
    crossroads.parse(relativeUrl)
    
    

});
if(!$('body').hasClass('shop-body')){
  History.Adapter.trigger(window, 'statechange')
}
