var verge = require('verge');
var renderQue = require('./render');

var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

var 
    lastScrollY     = window.pageYOffset,
    ticking         = false
    curr = null,
    scrollPoint = 0,
    scrollDistance = 0,
    timeoutid = 0;

/**
 * Callback for our scroll event - just
 * keeps track of the last scroll value
 */
 
function scroller() { 
    this.isScrolling = false
    renderQue.add(this)
    $(window).on('scroll', bind(this, this.scroll))
}

scroller.prototype.scroll = function(){
    
    this.isScrolling = true
    clearTimeout( timeoutid );
    var that = this
    
    timeoutid = setTimeout(function() {
  		that.isScrolling  = false
  		scrollDistance = 0
  		scrollPoint = window.pageYOffset;
  		
  	}, 100 )
}

scroller.prototype.renderQueCall = function(){
  
  if(!ticking  && this.isScrolling ) {
    ticking = true;
    this.render();
  }
  
}

scroller.prototype.render =function(){
    
    var scrollDirection = (window.pageYOffset - lastScrollY) > 0 ? !0 : !1
    stepSensor(scrollDirection)

    function stepSensor(dir) {
    
       if(curr != dir){
         scrollPoint = window.pageYOffset;
         scrollDistance = 0;
         //console.log(scrollPoint)
       }
       
       scrollDistance = scrollPoint - window.pageYOffset;
       
       inview = window.pageYOffset > 200
       if(inview){
         $('.shop-aside').addClass( 'has-nav' )
       }
       else {
        $('.shop-aside').removeClass( 'has-nav' ) 
       }
       
       if(scrollDistance > 16 ) {
         $( '.nav' ).addClass( 'has-nav' )
       }
       else if (scrollDistance < 0 && inview){
         $( '.nav' ).removeClass( 'has-nav' )
       }
         
       lastScrollY =  window.pageYOffset
       curr = dir
    }
    
    ticking = false;
}

module.exports = {
 scroller: new scroller()
}