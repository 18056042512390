var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

var verge = require('verge')
var util = require('util').inherits
var defaultpage = require('./defaultpage')
var imagesLoaded = require('imagesloaded');
require("./../../bower_components/gsap/src/uncompressed/TweenMax.js");

function homepage(name, stage){
   
   this.name = name
   this.counter = 7   
   this.stage = stage
   this.el = $('#content')
   this.el_moving = []
   this.el_data = []
   this.swiperInstance = null
   
   
}

util(homepage, defaultpage)

homepage.prototype.scroll = function (event) {

  
   this.el_data = $.map(this.el_moving , function( el ) {
      return verge.rectangle(el).top + 100
    });
    
  for(var m = 0; m < this.el_moving.length; m++) {
     var mover = this.el_moving[m]
     
     if( this.el_data[m] - event.h < 0  ) {
         mover.classList.add('is-visible');
     } else {
         mover.classList.remove('is-visible');
     }
  }
  
}

homepage.prototype.resize = function (e) {
  
}


homepage.prototype.hide = function() {
  
  //this.exit()
  if(typeof(this.swiperinstance) == "function"){
    this.swiperinstance.destroy()
  }
  TweenMax.killAll(false, false, true, false);
  
  var h = this.stage.w
  TweenMax.to(this.el, .71, { x: h , alpha: 0, onComplete: bind(this, this.onHidden),  ease: Power4.easeInOut })

}


homepage.prototype.onHidden = function() { 
  this.hidden = true
}

homepage.prototype.animateOut = function() {
  
  this.exit()
  if(typeof(this.swiperinstance) == "function"){
    this.swiperinstance.destroy()
  }
  TweenMax.killAll(false, false, true, false);
  
  time = this.hidden ? 0 : .71
  var h = this.stage.w
  TweenMax.to(this.el, time, {x:h, alpha: 0,  onComplete: bind(this, this.onComplete), ease: Power4.easeInOut})

}

homepage.prototype.onComplete = function() {
  
  this.destroy()
  this.dealoc()
  
}

homepage.prototype.canEnter = function() {
  
  this.enter()
  $('body').removeClass('is-animating')
  this.el_moving = document.querySelectorAll('.box');   
  this.stage.resize()
  
  if(typeof(this.swiperinstance) == "function"){
    this.swiperinstance.destroy()
  }
  this.swiperinstance = new Swiper ('.swiper', {
    speed: 731,
    effect: 'fade',
    fade: {
      crossFade: false
    },
    autoplay: 2000,
    autoplayDisableOnInteraction: false,
    observer: true,
    observeParents: true,
    spaceBetween: 0,
    loop: true
  })
  
  var el = document.querySelector('.about-text');
  if(el) {
  SimpleScrollbar.initEl(el);
  }
  
}

homepage.prototype.headerLoaded = function(instance) {
  
  //TweenMax.to(this.el, 1, {alpha: 1})
  
  TweenMax.set(this.el, {alpha:0, x: this.stage.w })
  TweenMax.to(this.el, 1, {alpha: 1, x: 0, ease: Power4.easeInOut})
  
  this.canEnter()
  
}

homepage.prototype.animateIn = function(initialpageload) {
  
  this.initialpageload = initialpageload
  this.initListeners()
  this.headerLoaded()
  
  //this.imgLoad = imagesLoaded( 'header', { background: true } );
  //this.imgLoad.on( 'always', bind(this, this.headerLoaded) );
}

homepage.prototype.dealoc = function () {
   
   $(window).off("mousewheel.scr")
   this.imgLoad = null
   this.removeAllListeners()
}


module.exports = homepage