var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

var renderQue = {
    
    items: [],
    running: false,
    rAF: null,
    
    render: function () {
        if (!this.items || 0 === this.items.length || !this.running) 
        return this.running = false, void cancelAnimationFrame(this.rAF);
        
        for (var t = 0; t < this.items.length; t++) {
          this.items[t].renderQueCall();
        }
        
        this.running && (this.rAF = window.requestAnimationFrame(bind(this, this.render)))
    },
    
    has: function (t) {
        return -1 != this.items.indexOf(t)
    },
    
    add: function (t) {
        if (t && this.items) {
            if (void 0 === t.renderQueCall) throw new Error("RenderQue requires objects to have a renderQueCall method for callback."); - 1 === this.items.indexOf(t) && this.items.push(t)
        }
        this.running || (this.running = !0, this.rAF = window.requestAnimationFrame(bind(this, this.render)))
    },
    
    remove: function (t) {
        this.items && t && -1 !== this.items.indexOf(t) && this.items.splice(this.items.indexOf(t), 1), 0 === this.items.length && (this.running = !1)
    }
    
}

module.exports = renderQue