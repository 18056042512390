require('./util')
require('history.js');
require("./../../bower_components/gsap/src/uncompressed/TweenMax.js");
require('scrollPlugin');

require('lazysizes/plugins/unveilhooks/ls.unveilhooks')
require('lazysizes/plugins/object-fit/ls.object-fit')
require('lazysizes/plugins/parent-fit/ls.parent-fit')
require('lazysizes/plugins/respimg/ls.respimg')
require('lazysizes')

var menu = $('.body .nav a:not(.ext, .collection-but, .categories-but), .body .logo a'),
    $window = $(window),
    pages = require('./pages'),
    marker = $('.marker'),
    shop = require('./shop'),
    stage = require('./stage');
    

var stag = new stage()

function scroller(event) {
  marker.toggleClass('is-hide', event._windowScrollY > 0)
  $('.backtotop').toggleClass('is-visible', event._windowScrollY > 500)

}

stag.on('stage:scroll', scroller)

$('#content').on ('click', '.next-article', function (e) { 
    e.preventDefault()
    var url = $(this).attr('href') 
    History.pushState(null, null, url ); 
})

$('.backtotop').on ('click', function(e){
  e.preventDefault()
  TweenMax.to( window, 1, {scrollTo:{ y:0, autoKill:true }, ease:Power4.easeOut });
})

$('.collection-but').on ('click', function (e) {
  e.preventDefault()
  $('body').addClass('has-collection')
  $('body').removeClass('has-categories')
})

$('.categories-but').on ('click', function (e) {
  e.preventDefault()
  $('body').addClass('has-collection has-categories')
})



menu.on ('click', function (e) {
 
    e.preventDefault()

    $('body').removeClass('has-collection  has-categories')
    $('body').removeClass('has-menu')
    $('.hamburger').removeClass('is-active')
    $('.switch').removeClass('is-active')
    $('.shop-aside').removeClass('is-active')
    
    var url = $(this).attr('href') 
    History.pushState(null, null, url ); 
 
})

$('.marker').on ('click', function (e) {
  e.preventDefault()
  var h = $(window).height();
  
  TweenMax.to( window, 2, {scrollTo:{ y:h, autoKill:true }, ease:Power4.easeOut });
})

$('.hamburger').on ('click', function (e) { 
  $('body').removeClass('has-collection')
  $('body').toggleClass('has-menu')
  $('.hamburger').toggleClass('is-active')
  $('.switch').toggleClass('is-active')
  $('.shop-aside').toggleClass('is-active')
})
