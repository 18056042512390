var renderQue = require('./render');
var util = require('util').inherits
var EventEmitter = require('eventemitter3')

var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

function StageModel() {
  
  window.addEventListener("resize", bind(this, this.resize), !1)
  window.addEventListener("orientationchange", bind(this, this.resize), !1) 
  window.addEventListener("scroll", bind(this, this.scroll), !1)
  this.updateScrollY(),
  this.resize()
  renderQue.add(this)
}

util(StageModel, EventEmitter);


StageModel.SCROLL = "stage:scroll"
StageModel.RESIZE = "stage:resize"
StageModel.RESPONSIVE_CHANGE = "stage:responsive"
StageModel.ORIENTATION_PORTRAIT = "portrait"
StageModel.ORIENTATION_LANDSCAPE = "landscape"

 
StageModel.prototype.w = 0
StageModel.prototype.h = 0
StageModel.prototype.halfh = 0
StageModel.prototype.halfw = 0
StageModel.prototype.scrollDirection = ""
StageModel.prototype.scrolling = !1
StageModel.prototype._tempScrollY = 0
StageModel.prototype._mediaQueries = null
StageModel.prototype.currentMQ = ""
StageModel.prototype.performanceTimer = 0
StageModel.prototype.orientationMode = ""

Object.defineProperties(StageModel.prototype, {
    scrollTop: {
        get: function () {
            return this._windowScrollY
        }
    },
    scrolltop: {
        get: function () {
            return this._windowScrollY
        }
    }
})
 
StageModel.prototype.renderQueCall = function () {
    return this.scrolling ? (this.scrolling = !1, this.updateScrollY()) : void 0
}

StageModel.prototype.scroll = function (t) {
    return this.scrolling = !0
}

StageModel.prototype.updateScrollY = function () {
    return 0 === document.documentElement.scrollTop ? this._tempScrollY = document.body.scrollTop : this._tempScrollY = document.documentElement.scrollTop, 
    this.handleScroll()
} 

StageModel.prototype.handleScroll = function () { 

    return this._windowScrollY = this._tempScrollY, 
    this.scrollDirection = this._windowScrollY < this.scrollTop ? 1 : this._windowScrollY > this.scrollTop ? -1 : 0, 
    this.scrolltop = this.scrollTop, 
    this.emit(StageModel.SCROLL, this)
} 

StageModel.prototype.resize = function (t) {
    return this.updateScrollY(), 
    this.w = document.documentElement.clientWidth || window.innerWidth, 
    this.h = document.documentElement.clientHeight || window.innerHeight, 
    this.halfw = .5 * this.w, 
    this.halfh = .5 * this.h, 
    this.orientationMode = this.w / this.h <= .87 ? StageModel.ORIENTATION_PORTRAIT : StageModel.ORIENTATION_LANDSCAPE,
    this.emit(StageModel.RESIZE, this)
}

module.exports = StageModel