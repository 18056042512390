var util = require('util').inherits
var EventEmitter = require('eventemitter3')
var bind = function (t, e) {
    return function () {
        e.apply(t, arguments)
    }
}

function defaultpage(name){
  
}

util(defaultpage, EventEmitter);


defaultpage.prototype.initListeners = function(){
 
  this.scroller = bind(this, this.scroll)
  this.resizer = bind(this, this.resize)
  
  this.stage.on('stage:scroll', this.scroller)
  this.stage.on('stage:resize', this.resizer)
}


defaultpage.prototype.exit = function(){

  this.stage.off('stage:scroll', this.scroller )
  this.stage.off('stage:resize', this.resizer)
}


defaultpage.prototype.enter = function () {
  
  this.emit('page:enter')
}

defaultpage.prototype.destroy = function () {

  this.emit('page:exit')
}


module.exports = defaultpage